<template>
  <page>
    <div class="py-6 flex">
      <label for="file-upload" class="mx-auto cursor-pointer">
        <img
          :src="avatarSrc"
          ref="avatarPreview"
          class="w-32 h-32 rounded-full border border-white shadow object-cover"
        />
        <div class="text-center mt-4 text-xs text-active">
          {{ $t('actions.edit') }}
        </div>
      </label>
      <input
        type="file"
        name="file"
        accept="image/*"
        class="hidden"
        @change="onUploadAvatar"
        id="file-upload"
      />
    </div>
    <div class="mx-4 sm:w-96 sm:mx-auto -mt-28">
      <card class="w-full pt-24">
        <form @submit.prevent="saveProfile">
          <field
            name="name"
            required
            v-model="profile.name"
            :label="$t('profile.name')"
          />
          <field
            name="email"
            type="email"
            required
            v-model="profile.email"
            :label="$t('profile.email')"
          />
          <field
            name="phone"
            type="tel"
            required
            v-model="profile.phone"
            :label="$t('profile.phone')"
          />
          <div class="flex justify-end -mx-2">
            <action
              class="mx-2"
              variant="link"
              type="button"
              @click="resetForm"
              :disabled="!isDirty || isBusy"
              >{{ $t('actions.reset') }}</action
            >
            <action
              class="mx-2"
              variant="primary"
              type="submit"
              :disabled="!isDirty"
              :loading="isBusy"
              >{{ $t('actions.save') }}</action
            >
          </div>
        </form>
      </card>
    </div>
  </page>
</template>

<script>
import { Form } from '@/services'

export default {
  data() {
    const nocache = Math.floor(100000 * Math.random())
    return {
      nocache,
      profile: new Form({ name: '', email: '', phone: '' }),
      isBusy: false
    }
  },
  mounted() {
    this.resetForm()
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    avatarSrc() {
      return `${process.env.VUE_APP_SERVER_URL}/media/avatar/${this.user._id}.png?_=${this.nocache}`
    },
    isDirty() {
      return (
        this.profile.email !== this.user.email ||
        this.profile.name !== this.user.name ||
        this.profile.phone !== this.user.phone
      )
    }
  },
  methods: {
    resetForm() {
      this.profile = new Form({
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone
      })
    },
    async onUploadAvatar(event) {
      const {
        srcElement: { files }
      } = event
      if (files && files.length === 1 && files[0]) {
        await this.uploadFile(files[0])
        this.nocache = Math.floor(1000000 * Math.random())
      }
    },
    async uploadFile(file) {
      const formData = new FormData()
      formData.append('image', file)
      await this.$http.put('/media/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.$bus.$emit('avatar-changed')
    },
    async saveProfile() {
      try {
        this.isBusy = true
        const response = await this.$http.put(
          '/auth/profile',
          this.profile.data
        )
        this.$store.dispatch('login', response.data.data)
      } catch (err) {
        this.$toast('danger', 'errors.profileUpdate')
      } finally {
        this.isBusy = false
      }
    }
  }
}
</script>
